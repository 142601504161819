.refuse {
    background-color: #f5365c;
    padding: 12px;
    border-radius: 5px;
    color: #fff;
}

.affect {
    background-color: #11cdef;
    padding: 12px;
    border-radius: 5px;
    color: #fff;
}

.waiting {
    background-color: #f7fafc;
    padding: 12px;
    border-radius: 5px;
    color: #212529;
}