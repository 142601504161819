.ck-editor__editable_inline {
  min-height: 400px;
}

.margin-p{
  margin: 0px;
}

.title-warning{
  position: relative;
  max-width: 100%;
  margin: 0px 0px 0.4em;
  padding: 0px;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  overflow-wrap: break-word;
}